import request from '@/utils/request';

/**
 * 获取公司的相关配置
 * @param {*} query
 * @returns
 */
export function getConfig(query) {
  return request({
    url: '/config/get-config',
    method: 'get',
    params: query
  });
}