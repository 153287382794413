<template>
  <div class="footer">
    <div class="footer-content wow animate__fadeInUp">
      <div class="container">
        <div class="footer-nav">
          <el-popover placement="top" width="224" trigger="hover">
            <img src="../../assets/images/code.png" width="200px" height="200px" />
            <a slot="reference"><i class="icon icon-weixin"></i></a>
          </el-popover>
          <a><i class="icon icon-xinlangweibo"></i></a>
          <a><i class="icon icon-youxiang-"></i></a>
          <a><i class="icon icon-fenxiang"></i></a>
        </div>
        <el-row :gutter="50">
          <el-col :span="6">
            <div class="footer-logo wow animate__zoomIn">
              <img src="../../assets/images/footer-logo.png" />
            </div>
          </el-col>
          <el-col :span="3" class="wow animate__fadeInUp">
            <div class="size-16 bold white-color">{{ $t('menu.products') }}</div>
            <ul>
              <li>
                <a>{{ $t('footer.productSeries') }}</a>
              </li>
            </ul>
          </el-col>
          <el-col :span="3" class="wow animate__fadeInUp">
            <div class="size-16 bold white-color">{{ $t('menu.medicalField') }}</div>
            <ul>
              <li>
                <a>{{ $t('menu.medicalField') }}</a>
              </li>
            </ul>
          </el-col>
          <el-col :span="3" class="wow animate__fadeInUp">
            <div class="size-16 bold white-color">{{ $t('menu.news') }}</div>
            <ul>
              <li>
                <a>{{ $t('footer.induxtryDynamics') }}</a>
              </li>
              <li>
                <a>{{ $t('footer.companyNews') }}</a>
              </li>
            </ul>
          </el-col>
          <el-col :span="3" class="wow animate__fadeInUp">
            <div class="size-16 bold white-color">{{ $t('menu.aboutUs') }}</div>
            <ul>
              <li>
                <a>{{ $t('footer.companyIntroduction') }}</a>
              </li>
              <li>
                <a>{{ $t('footer.history') }}</a>
              </li>
              <li>
                <a>{{ $t('footer.corporateCulture') }}</a>
              </li>
            </ul>
          </el-col>
          <el-col :span="6" class="wow animate__fadeInUp">
            <div class="size-16 bold white-color">{{ $t('menu.contactUs') }}</div>
            <ul>
              <li v-if="sysConfig.tel"><i class="icon icon-24gf-phoneLoudspeaker mr3"></i>{{ sysConfig.tel }}</li>
              <li v-if="sysConfig.email"><i class="icon icon-youxiang- mr3"></i>{{ sysConfig.email }}</li>
              <li v-if="sysConfig.address"><i class="icon icon-daohangdizhi mr3"></i>{{ sysConfig.address }}</li>
            </ul>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="footer-copyright wow animate__fadeInUp">
      <div class="container">
        <div class="size-14 white-color text-center">{{ $t('footer.bottom') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getConfig } from '@/api/config';
export default {
  name: 'Footer',
  data() {
    return {
      // 系统的配置信息
      sysConfig: {},
      // 语言（默认中文）
      lang: this.$store.state.lang
    };
  },
  watch: {
    getLang(newValue) {
      this.lang = newValue;
      this.getConfig();
    }
  },
  computed: {
    getLang() {
      return this.$store.state.lang;
    }
  },
  created() {
    // 获取系统配置信息
    this.getConfig();
  },
  methods: {
    // 获取系统配置信息
    getConfig() {
      getConfig({ lang: this.lang }).then(res => {
        if (res && res.id) {
          this.sysConfig = res;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  overflow: hidden;
}
.footer-content {
  background-color: #1f9d88;
  padding: 40px 0 20px;
  ul {
    margin-top: 20px;
    li {
      font-size: 14px;
      color: #fff;
      margin-bottom: 15px;
    }
  }
}
.footer-logo {
  text-align: right;
  img {
    max-width: 100%;
  }
}
.footer-copyright {
  background-color: #1f9d88;
  padding: 15px 0;
  line-height: 30px;
}
.footer-nav {
  text-align: right;
  padding-bottom: 20px;
  a {
    display: inline-block;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #fff;
    margin-left: 20px;
    color: #1f9d88;
    cursor: pointer;
    line-height: 34px;
    text-align: center;
    vertical-align: middle;
    .icon {
      font-size: 20px;
    }
  }
}
</style>
