module.exports = {
  // 主菜单
  menu: {
    home: '首页',
    products: '产品中心',
    medicalField: '医学天地',
    news: '新闻中心',
    aboutUs: '关于我们',
    contactUs: '联系我们'
  },
  searchHolder: '请输入关键字',
  // 底部
  footer: {
    productSeries: '产品系列',
    induxtryDynamics: '行业动态',
    companyNews: '公司动态',
    companyIntroduction: '公司介绍',
    history: '发展历程',
    corporateCulture: '企业文化',
    bottom: 'Copyright © 2021-2028 花沐医疗科技（上海）有限公司网站 版权所有 沪ICP备2021017147号-1'
  },
  // 首页
  home: {
    productsTitle: '花沐医疗专注于可吸收再生医学，服务于五大医疗领域',
    productRecommendation: '产品推荐',
    technologyPlatform: '技术平台',
    activity: '最新活动',
    business: '业务布局及合作单位',
    learnMore: '了解更多'
  },
  // 产品中心
  product: {
    productRelation: '相关产品',
    docRelation: '相关文献'
  },
  // 新闻中心页面
  news: {
    learnMore: '了解更多'
  },
  // 关于我们页面
  aboutUs: {
    companyIntroduction: '公司介绍',
    developmentMilestones: '发展里程碑',
    corporateCulture: '企业文化'
  },
  // 联系我们页面
  contactUs: {
    contactMethod: '联系方式',
    onlineMessage: '在线留言',
    name: '名字',
    telephone: '电话',
    mail: '邮箱',
    message: '留言',
    validateCode: '验证码',
    reset: '重新填写',
    submit: '提交'
  }
};
