<template>
  <div class="header wow animate__fadeInDown">
    <div class="header-top">
      <div class="container">
        <span v-if="sysConfig.tel"><i class="icon icon-24gf-phoneLoudspeaker"></i>{{ sysConfig.tel }}</span>
        <span v-if="sysConfig.email"><i class="icon icon-youxiang-"></i>{{ sysConfig.email }}</span>
        <span v-if="sysConfig.address"><i class="icon icon-daohangdizhi"></i>{{ sysConfig.address }}</span>
        <span class="change-lang-container">
          <span :class="lang === 1 ? 'active' : ''" @click="langChange(1)">CN</span>
          <span :class="lang === 2 ? 'active' : ''" @click="langChange(2)">EN</span>
        </span>
      </div>
    </div>
    <div class="header-nav">
      <div class="container">
        <el-row :gutter="20">
          <el-col :span="4"
            ><div class="header-logo"><img src="../../assets/images/logo.png" /></div
          ></el-col>
          <el-col :span="14" class="text-right">
            <el-menu :default-active="activeNavIndex" class="nav" mode="horizontal" @select="handleSelect">
              <el-menu-item index="n1" @click="home" :class="{ 'no-active': productCenterActive }">{{ $t('menu.home') }}</el-menu-item>

              <!-- 产品中心 - 开始 -->
              <el-submenu index="n2" :class="{ active: productCenterActive }">
                <template slot="title">
                  <span class="sub-name">{{ $t('menu.products') }}</span>
                </template>
                <template v-for="item in productCenterNavList">
                  <!-- 有子菜单的 -->
                  <el-submenu v-if="item.tagList.length > 0" :index="item.tagValue">
                    <template slot="title">{{ item.tagName }}</template>
                    <el-menu-item v-for="cItem in item.tagList" :index="cItem.tagValue">{{ cItem.tagName }}</el-menu-item>
                  </el-submenu>
                  <!-- 没有子菜单 -->
                  <el-menu-item v-if="item.tagList.length <= 0" :index="item.tagValue">{{ item.tagName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-menu-item>
                </template>
              </el-submenu>
              <!-- 产品中心 - 结束 -->

              <el-menu-item index="n3" @click="medical" :class="{ 'no-active': productCenterActive }">{{ $t('menu.medicalField') }}</el-menu-item>
              <el-menu-item index="n4" @click="news" :class="{ 'no-active': productCenterActive }">{{ $t('menu.news') }}</el-menu-item>
              <el-menu-item index="n5" @click="about" :class="{ 'no-active': productCenterActive }">{{ $t('menu.aboutUs') }}</el-menu-item>
              <el-menu-item index="n6" @click="contact" :class="{ 'no-active': productCenterActive }">{{ $t('menu.contactUs') }}</el-menu-item>
            </el-menu>
          </el-col>
          <el-col :span="6">
            <div class="header-search-box">
              <el-input :placeholder="$t('searchHolder')" v-model="searchStr" clearable @keydown.enter.native="search">
                <template slot="append">
                  <i class="icon icon-sousuo" @click="search"></i>
                </template>
              </el-input>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getConfig } from '@/api/config';
import { getProcuctCategoryList } from '@/api/product';
export default {
  name: 'Header',
  data() {
    return {
      // 系统的配置信息
      sysConfig: {},
      // 导航中默认选择的索引
      activeNavIndex: 'n1',
      // 选择的是否是产品中心
      productCenterActive: false,
      // 产品中心的树形菜单
      productCenterNavList: [],
      // 输入的搜索的字符串
      searchStr: '',
      // 语言（默认中文）
      lang: this.$store.state.lang
    };
  },
  created() {
    // 获取系统配置信息
    this.getConfig();
    // 获取产品中心的导航
    this.getProcuctCategoryList();
  },
  watch: {
    $route: {
      handler: function (val, oldValue) {
        if (val) {
          this.getActiveNavIndex(val);
        }
      },
      deep: true
    },
    getLang(newValue) {
      this.lang = newValue;
      this.getConfig();
      this.getProcuctCategoryList();
    }
  },
  computed: {
    getLang() {
      return this.$store.state.lang;
    }
  },
  methods: {
    // 切换语言
    langChange(value) {
      if (value === 1) {
        this.$i18n.locale = 'zh';
      } else {
        this.$i18n.locale = 'en';
      }
      this.$store.commit('setLang', value);
    },
    // 动态获取activeNavIndex
    getActiveNavIndex(val) {
      this.productCenterActive = false;
      if (val.fullPath) {
        if (val.fullPath.indexOf('/product') === 0) {
          if (val.params.nav3 !== '0') this.activeNavIndex = val.params.nav3;
          else if (val.params.nav2 !== '0') this.activeNavIndex = val.params.nav2;
          else this.activeNavIndex = 'n2';
          this.productCenterActive = true;
        } else if (val.fullPath.indexOf('/medical') === 0) this.activeNavIndex = 'n3';
        else if (val.fullPath.indexOf('/news') === 0) this.activeNavIndex = 'n4';
        else if (val.fullPath.indexOf('/about') === 0) this.activeNavIndex = 'n5';
        else if (val.fullPath.indexOf('/contact') === 0) this.activeNavIndex = 'n6';
        else this.activeNavIndex = 'n1';
      }
    },
    // 获取系统配置信息
    getConfig() {
      getConfig({ lang: this.lang }).then(res => {
        if (res && res.id) {
          this.sysConfig = res;
        }
      });
    },
    // 获取产品中心的导航
    getProcuctCategoryList() {
      getProcuctCategoryList({ classCode: 'CHANPINZHONGXIN', lang: this.lang }).then(res => {
        if (res && res.id && res.tagList && res.tagList.length > 0) {
          this.productCenterNavList = res.tagList;
        }
      });
    },
    // 切换产品中心的菜单
    handleSelect(key, keyPath) {
      if (keyPath.length === 1) {
        this.$router.push(
          `/product/${keyPath[0]}/0/0`,
          () => {},
          () => {}
        );
      } else if (keyPath.length === 2) {
        this.$router.push(
          `/product/${keyPath[0]}/${keyPath[1]}/0`,
          () => {},
          () => {}
        );
      } else if (keyPath.length === 3) {
        this.$router.push(
          `/product/${keyPath[0]}/${keyPath[1]}/${keyPath[2]}`,
          () => {},
          () => {}
        );
      }
    },
    // 搜索
    search() {
      if (this.searchStr) {
        this.searchStr = this.searchStr.trim();
      }
      if (this.searchStr) {
        this.$router.push(
          '/product/search/' + encodeURI(this.searchStr),
          () => {},
          () => {}
        );
      } else {
        this.$message.error('请输入关键字');
      }
    },
    home() {
      this.productCenterActive = false;
      this.$router.push(
        '/',
        () => {},
        () => {}
      );
    },
    product() {
      this.productCenterActive = true;
      this.$router.push(
        '/product/n2/0/0',
        () => {},
        () => {}
      );
    },
    medical() {
      this.productCenterActive = false;
      this.$router.push(
        '/medical',
        () => {},
        () => {}
      );
    },
    news() {
      this.productCenterActive = false;
      this.$router.push(
        '/news',
        () => {},
        () => {}
      );
    },
    about() {
      this.productCenterActive = false;
      this.$router.push(
        '/about',
        () => {},
        () => {}
      );
    },
    contact() {
      this.productCenterActive = false;
      this.$router.push(
        '/contact',
        () => {},
        () => {}
      );
    }
  }
};
</script>

<style lang="less" scoped>
.header .header-nav {
  height: 90px;
  padding: 15px 0;
}
.header-top {
  background-color: #1f9d88;
  height: 42px;
  line-height: 42px;
  text-align: right;
  color: #fff;
  font-size: 13px;
  span {
    margin-left: 36px;
    i {
      margin-right: 4px;
    }
  }
}
.header-logo {
  height: 52px;
  margin-top: 4px;
  img {
    height: 100%;
  }
}
.nav.el-menu {
  /*width:600px;*/
  border-bottom: none;
  margin-left: 20px;
  .el-menu-item {
    font-size: 18px;
    color: #333;
    padding: 0 5px;
    margin: 0 10px;
    &.is-active {
      color: #036eb8;
      border-color: #036eb8;
      &.no-active {
        color: #333;
        border-color: transparent;
      }
    }
  }
  .el-submenu.active {
    /deep/ .el-submenu__title {
      border-bottom: #036eb8 solid 2px !important;
      .sub-name {
        color: #036eb8;
      }
    }
  }
}

.sub-name {
  font-size: 18px;
  color: #333;
}
.header-search {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  text-align: center;
  line-height: 64px;
  background-color: #0d6fb8;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #2c86c9;
  }
}
.header-search-box {
  margin-top: 10px;
  /deep/ .el-input__inner {
    border-color: #036eb7;
    font-size: 16px;
  }
  /deep/ .el-input-group__append {
    cursor: pointer;
    background-color: transparent;
    color: #036eb7;
    transition: all 0.35s;
    border-color: #036eb7;
    &:hover {
      background-color: #036eb7;
      color: #fff;
    }
  }
}

.change-lang-container {
  span {
    box-sizing: border-box;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    color: #fff;
    margin: 0;
    padding: 0 5px;
    border: 1px solid #fff;
    cursor: pointer;
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-right: none;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &.active {
      background-color: #2770b4;
    }
  }
}
</style>
