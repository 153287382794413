import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // 首页
  { path: '/', component: () => import('@/views/Home/Home.vue') },
  // 最新活动详情页
  { path: '/activity/details/:id', component: () => import('@/views/Activity/Activity.details.vue') },
  // 产品中心
  { path: '/product/:nav1/:nav2/:nav3', component: () => import('@/views/Product/Product.vue') },
  // 产品详情页
  { path: '/product/details/:id', component: () => import('@/views/Product/Product.details.vue') },
  // 产品搜索
  { path: '/product/search/:key', component: () => import('@/views/Product/Product.search.vue') },
  // 医学天地
  { path: '/medical', component: () => import('@/views/Medical/Medical.vue') },
  // 医学天地详情
  { path: '/medical/details/:id', component: () => import('@/views/Medical/Medical.details.vue') },
  // 新闻中心
  { path: '/news', component: () => import('@/views/News/News.vue') },
  // 新闻中心详情
  { path: '/news/details/:id', component: () => import('@/views/News/News.details.vue') },
  // 往期视频详情
  { path: '/news/video-details/:id', component: () => import('@/views/News/Video.details.vue') },
  // 关于我们
  { path: '/about', component: () => import('@/views/About/About.vue') },
  // 联系我们
  { path: '/contact', component: () => import('@/views/Contact/Contact.vue') },
  // 兜底
  { path: '*', component: () => import('@/views/NotFound/NotFound.vue') }
];

const router = new VueRouter({
  routes
});

export default router;
