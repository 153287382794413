import request from '@/utils/request';

/**
 * 获取首页的产品推荐
 * @param {*} query
 * @returns
 */
export function getProductPushList(query) {
  return request({
    url: '/product/get-push-list',
    method: 'get',
    params: query
  });
}

/**
 * 获取所有的产品分类
 * @param {*} query - classCode=CHANPINZHONGXIN
 * @returns
 */
export function getProcuctCategoryList(query) {
  return request({
    url: '/product/get-product-category-list',
    method: 'get',
    params: query
  });
}

/**
 * 获取指定类别的产品列表
 * @param {*} query - categoryValue 1骨科运动医学 2
 * @returns
 */
export function getProductList(query) {
  return request({
    url: '/product/get-list',
    method: 'get',
    params: query
  });
}

/**
 * 分页查找
 * @param {*} query
 * @returns
 */
export function getPaged(query) {
  return request({
    url: '/product/get-paged',
    method: 'get',
    params: query
  });
}

/**
 * 获取指定的产品详情
 * @param {*} id
 * @returns
 */
export function getProduct(query) {
  return request({
    url: '/product/get',
    method: 'get',
    params: query
  });
}
