module.exports = {
  // 主菜单
  menu: {
    home: 'Home',
    products: 'Products',
    medicalField: 'Medical Field',
    news: 'News',
    aboutUs: 'About Us',
    contactUs: 'Contact Us'
  },
  searchHolder: 'Please enter keyword',
  // 底部
  footer: {
    productSeries: 'Product Series',
    induxtryDynamics: 'Industry Dynamics',
    companyNews: 'Company News',
    companyIntroduction: 'Company Introduction',
    history: 'History',
    corporateCulture: 'Corporate Culture',
    bottom: 'Copyright © 2021-2028 Huamu Medical Technology (Shanghai) Co., Ltd. Website. All rights reserved. Shanghai ICP No. 2021017147-1'
  },
  // 首页
  home: {
    productsTitle: 'Farmove Medical focuses on absorbable Regenerative medicine and serves five major medical fields',
    productRecommendation: 'Product Recommendation',
    technologyPlatform: 'Technology Platform',
    activity: 'Activity',
    business: 'Business layout and Cooperative units',
    learnMore: 'Learn more'
  },
  // 产品中心
  product: {
    productRelation: '相关产品',
    docRelation: '相关文献'
  },
  // 新闻中心页面
  news: {
    learnMore: 'Learn More'
  },
  // 关于我们页面
  aboutUs: {
    companyIntroduction: 'Company Introduction',
    developmentMilestones: 'Development Milestones',
    corporateCulture: 'Corporate Culture'
  },
  // 联系我们页面
  contactUs: {
    contactMethod: 'Contact',
    onlineMessage: 'Online Message',
    name: 'Name',
    telephone: 'Telephone',
    mail: 'Mail',
    message: 'Message',
    validateCode: 'Verification Code',
    reset: 'Reset',
    submit: 'Submit'
  }
};
