import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedstate from 'vuex-persistedstate';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    lang: 1 // 1是中文，2是英文
  },
  mutations: {
    // 描述了数据在某种场景下的变化，提交 mutation 是数据改变的唯一原因
    setLang(state, value) {
      state.lang = value;
    }
  },
  plugins: [
    createPersistedstate({
      key: 'fm-lang',
      storage: window.sessionStorage
    })
  ]
});

export default store;