<template>
  <div id="app" class="app-container">
    <app-header v-if="header_show"></app-header>
    <router-view />
    <app-footer v-if="footer_show"></app-footer>
  </div>


</template>

<script>
  import Header from './components/header/header'
  import Footer from './components/footer/footer'
  export default {
    name: 'App',
    data(){
      return {
        header_show:true,
        footer_show:true,
      }
    },
    components: {
      'app-header': Header,
      'app-footer': Footer,
    },
    methods:{
      // 是否显示头部
      header:function(bool){
        this.header_show = bool;
      }

    }
  }
</script>
<style lang="less" scoped>
</style>
