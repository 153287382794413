import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueI18n from 'vue-i18n';
import { Menu, Submenu, MenuItem, MenuItemGroup, Row, Col, Popover, Input, Carousel, CarouselItem, Card, Button, Backtop, Tabs, TabPane, Image, Pagination, Form, FormItem, Breadcrumb, BreadcrumbItem, Empty, Loading, Message } from 'element-ui';
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Row);
Vue.use(Col);
Vue.use(Popover);
Vue.use(Input);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Card);
Vue.use(Button);
Vue.use(Backtop);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Image);
Vue.use(Pagination);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Empty);

Vue.use(Loading.directive);

import 'animate.css';
import 'wowjs/css/libs/animate.css'; // 此地方要引入两个css
import wow from 'wowjs';
Vue.prototype.$wow = wow;
Vue.prototype.$message = Message;

import BaiduMap from 'vue-baidu-map'; // 导入地图
// 进行全局注册，一次性引入百度地图组件库的所有组件
// ak为官方提供的密钥
Vue.use(BaiduMap, {
  ak: '98RapFsuhZRdf9CqMLvP5fZbG8Cn9XuO'
});

// 全局初始化自定义样式
import '@/assets/css/reset.less';
import '@/assets/fonts/iconfont.css';

import store from './store';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: store.state.lang === 1 ? 'zh' : 'en', // 根据仓库的数据来加载默认的语言
  messages: {
    zh: require('./lang/zh'),
    en: require('./lang/en')
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
