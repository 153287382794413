import axios from 'axios';
import { baseUrl } from './base-config';

const service = axios.create({
  baseURL: baseUrl + '/mobile-api/farmove',
  timeout: 100000
});

service.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

service.interceptors.response.use(
  function (response) {
    if (response && response.status === 200 && response.data && response.data.code === 200) {
      return Promise.resolve(response.data.data);
    } else {
      return Promise.reject(response.data);
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default service;
